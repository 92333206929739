import { format } from 'date-fns';
import { eCardStyle, eCustomFieldTypes, eDeliveryTypes, eMenuIconTypes, eMetaTheme, eTaxTypes } from './enums';

export const DEFAULT_MODE_THEME = 'sitchLight';
export const STANDARD_UPLOAD_LIMIT_IN_BYTES = 10485760; // 10

export const sitchShopDomains = [
  // UMB store
  'https://sitch.app/Shop?s=okgBTo4',
  'https://sitch.app/s/okgBTo4',
  'https://sitch.app/umb',

  // Prod links
  'https://sitch.app/store',
  'https://sitch.app/ca-store',
  'https://sitch.app/us-store',
  'https://sitch.app/uk-store',
  'https://sitch.app/eu-store',
  'https://sitch.app/int-store',
  'https://sitch.app/s/vXVPh4b', // ca
  'https://sitch.app/s/8cn6BGK', // us
  'https://sitch.app/s/U92jQPA', // uk
  'https://sitch.app/s/LeEDKV0', // eu
  'https://sitch.app/s/VdTDhAK', // int
  'https://sitch.app/s/o1TBomm', // us no delivery
  'https://sitch.app/Shop?p=store',
  'https://sitch.app/Shop?p=ca-store',
  'https://sitch.app/Shop?p=us-store',
  'https://sitch.app/Shop?p=uk-store',
  'https://sitch.app/Shop?p=eu-store',
  'https://sitch.app/Shop?p=int-store',
  'https://sitch.app/Shop?s=vXVPh4b', // ca
  'https://sitch.app/Shop?s=8cn6BGK', // us
  'https://sitch.app/Shop?s=U92jQPA', // uk
  'https://sitch.app/Shop?s=LeEDKV0', // eu
  'https://sitch.app/Shop?s=VdTDhAK', // int
  'https://sitch.app/Shop?s=o1TBomm', // us no delivery

  // Testing links
  'https://sitch-client-test.web.app/Shop?s=6hGkart',
  'http://localhost:8080/Shop?s=6hGkart',
  'http://localhost:8081/Shop?s=6hGkart',

  // Doubled for localhost testing of production code
  'http://localhost:8081/store',
  'http://localhost:8081/ca-store',
  'http://localhost:8081/us-store',
  'http://localhost:8081/uk-store',
  'http://localhost:8081/eu-store',
  'http://localhost:8081/int-store',
  'http://localhost:8081/s/vXVPh4b', // ca
  'http://localhost:8081/s/8cn6BGK', // us
  'http://localhost:8081/s/U92jQPA', // uk
  'http://localhost:8081/s/LeEDKV0', // eu
  'http://localhost:8081/s/VdTDhAK', // int
  'http://localhost:8081/s/o1TBomm', // us no delivery
  'http://localhost:8081/Shop?p=store',
  'http://localhost:8081/Shop?p=ca-store',
  'http://localhost:8081/Shop?p=us-store',
  'http://localhost:8081/Shop?p=uk-store',
  'http://localhost:8081/Shop?p=eu-store',
  'http://localhost:8081/Shop?p=int-store',
  'http://localhost:8081/Shop?s=vXVPh4b', // ca
  'http://localhost:8081/Shop?s=8cn6BGK', // us
  'http://localhost:8081/Shop?s=U92jQPA', // uk
  'http://localhost:8081/Shop?s=LeEDKV0', // eu
  'http://localhost:8081/Shop?s=VdTDhAK', // int
  'http://localhost:8081/Shop?s=o1TBomm', // us no delivery
];

export const getEmptyBasicMode = (): Mode => {
  return {
    docId: '',
    linkId: '',
    type: null, // Just as a default,
    name: '',
    displayName: '',
    index: 0,
    themeId: DEFAULT_MODE_THEME,
  };
};

export const getEmptyLinkListMode = (): Omit<LinkListMode, keyof Mode> => {
  return {
    message: '',
    links: [],
    images: [],
  };
};

export const getEmptyBusinessPaymentsMode = (): Omit<BusinessPaymentsMode, keyof Mode> => {
  return {
    message: '',
    currency: 'CAD',
    stripeAccountId: '',
    tipOptionsEnabled: false,
    taxRate: 0,
    amount: 0,
    requireName: false,
    preSubmissionCustomFormModeId: '',
    postSubmissionCustomFormModeId: '',
  };
};

export const getEmptyCustomFormMode = (): Omit<CustomFormMode, keyof Mode> => {
  return {
    message: '',
    requireName: true,
    requireEmail: true,
    requirePhoneNumber: false,
    customFields: [],
    emailForReceivingNotifications: '',
  };
};

export const getEmptyFilesMode = (): Omit<FilesMode, keyof Mode> => {
  return {
    message: '',
    hideIcons: false,
    files: [],
  };
};

export const getEmptyGalleryMode = (): Omit<GalleryMode, keyof Mode> => {
  return {
    galleryItems: [],
    message: '',
    galleryItemNamesEnabled: false,
  };
};

export const getEmptyBlogMode = (): Omit<BlogMode, keyof Mode> => {
  return {
    message: '',
    postReferences: [],
  };
};

export const getEmptyAccordionMode = (): Omit<AccordionMode, keyof Mode> => {
  return {
    message: '',
    sections: [],
  };
};

export const getEmptyGroupMode = (): Omit<GroupMode, keyof Mode> => {
  return {
    groupModeIds: [],
  };
};

export const getEmptySiteMode = (): Omit<SiteMode, keyof Mode> => {
  return {
    menuIconType: eMenuIconTypes.standard,
    emojiIcons: [],
    landingPageModeId: '',
    sitePageModeIds: [],
    images: [],
  };
};

export const getEmptyHtmlMode = (): Omit<HtmlMode, keyof Mode> => {
  return {
    html: '',
    js: '',
    css: '',
    minHeight: '',
    maxWidth: '',
  };
};

export const getEmptyShopMode = (): Omit<ShopMode, keyof Mode> => {
  return {
    message: '',
    checkoutSuccessMessage: '',
    requiresDeliveryAddress: false,
    stripeAccountId: '',
    tipOptionsEnabled: false,
    taxRate: 0,
    flatDeliveryFee: 0,
    hasDeliveryTaxRate: false,
    deliveryTaxRate: 0,
    currency: 'USD',
    preCheckoutOrderBreakdown: [],
    shopItemList: [],
    hasPromoCodes: false,
    allowSpecialRequests: false,
    categories: [],
    requireName: false,
    preSubmissionCustomFormModeId: '',
    postSubmissionCustomFormModeId: '',
    hasMaxOrderDistance: false,
    maxOrderDistance: 0,
    locationAddress: getEmptyDetailedAddress(),
    allowedCountriesForDelivery: [],
    freeShippingCountries: [],
    computeTaxRateBasedOnLocation: false,
    locationsToComputeTaxRateFor: {},
    deliveryType: eDeliveryTypes.local,
    taxType: eTaxTypes.userSpecified,
    images: [],
  };
};

export const getEmptyProfileMode = (): Omit<ProfileMode, keyof Mode> => {
  return {
    images: [],
    title: '',
    email: '',
    phone: '',
    phoneWork: '',
    phoneWorkExtension: '',
    organization: '',
    organizationUrl: '',
    website: '',
    websiteLabel: '',
    ctaButtonLabel: '',
    ctaButtonUrl: '',
    description: '',
    showLabelsForSocials: false,
    showAddAsContactPrompt: false,
    showContactDetailsPrompt: false,
    allowPeopleToSendTheirContactDetails: false,
    contactDetailsCustomFormModeId: '',
    contactDetailsFormUrl: '',
    locationAddress: getEmptyDetailedAddress(),
    locationLabel: '',
    hidePhone: false,
    hidePhoneWork: false,
    hideEmail: false,
    hideWebsite: false,
    hideOrganization: true,
    hideLocationAddress: false,
    hideSaveForLaterButton: false,
  };
};

export const getEmptyPersonalPaymentsMode = (): Omit<PersonalPaymentsMode, keyof Mode> => {
  return {
    // Crypto.
    bitcoinAddress: '',
    bitcoinEnabled: false,
    ethereumAddress: '',
    ethereumEnabled: false,
    litecoinAddress: '',
    litecoinEnabled: false,
    // Other.
    paypalEnabled: false,
    googlePayEnabled: false,
    applePayEnabled: false,
    venmoEnabled: false,
    cashAppEnabled: false,
    interacEnabled: false,
    paypalMeUrl: '',
    googlePayMeUrl: '',
    applePayMeUrl: '',
    venmoUrl: '',
    cashAppUrl: '',
    interacEmail: '',
    interacPhoneNumber: '',
    interacPassword: '',
    // Required.
    amount: 0,
    currency: '',
  };
};

export const getEmptyWifiMode = (): Omit<WifiMode, keyof Mode> => {
  return {
    ssid: '',
    wifiEncryption: 'WPA',
    wifiPassword: '',
  };
};

export const getEmptyUrlRedirectMode = (): Omit<UrlRedirectMode, keyof Mode> => {
  return {
    redirectUrl: '',
    putInIframe: false,
  };
};

export const getEmptyArticleMode = (): Omit<ArticleMode, keyof Mode> => {
  return {
    images: [],
    text: '',
  };
};

export const getEmptyEventsMode = (): Omit<EventsMode, keyof Mode> => {
  return {
    message: '',
    events: [],
  };
};

export const getEmptyBookingMode = (): Omit<BookingMode, keyof Mode> => {
  return {
    message: '',
    organizationName: '',
    availabilityTimeSlots: [],
    maxBookingsPerDay: null,
    bufferTimeBeforeInMinutes: 5,
    bufferTimeAfterInMinutes: 5,
    minimumSchedulingNoticeInMinutes: 60,
    timeSteps: 15,
    maximumSchedulingNoticeInDays: 30,
    maxSimultaneousBookingsForSameSlot: 1,
    durationPerBookingHours: 1,
    durationPerBookingMinutes: 0,
    closureDates: [],
    locationAddress: getEmptyDetailedAddress(),
    emailForReceivingNotifications: '',
    preSubmissionCustomFormModeId: '',
    postSubmissionCustomFormModeId: '',
  };
};

export const getEmptyDetailedAddress = (): DetailedAddress => {
  return {
    name: '',
    phoneNumber: '',
    addressLine2: '',
    additionalAddressDetails: '',
    formattedAddress: '',
    latitude: null,
    longitude: null,
    googlePlaceId: '',
    utcOffset: -1 * new Date().getTimezoneOffset(),
    address: {
      administrativeAreaLevel1: '',
      administrativeAreaLevel2: '',
      country: '',
      locality: '',
      postalCode: '',
      route: '',
      streetNumber: '',
    } as GoogleAddress,
  };
};

// Item templates

export const getEmptySitchEvent = (): SitchEvent => {
  return {
    id: '',
    name: '',
    eventDate: '',
    startTime: '',
    durationHours: 0,
    durationMinutes: 30,
    url: '',
    description: '',
    locationAddress: getEmptyDetailedAddress(),
    images: [],
    isHidden: false,
  };
};

export const getEmptyGalleryItem = (): GalleryItem => {
  return {
    id: '',
    name: '',
    description: '',
    images: [],
    isHidden: false,
  };
};

export const getEmptyBlogPostReference = (): BlogPostReference => {
  return {
    id: '',
    name: '',
    subtitle: '',
    author: '',
    images: [],
    goLiveDate: format(new Date(), 'yyyy-MM-dd'),
    goLiveTime: format(new Date(), 'HH:mm'),
    utcOffset: -1 * new Date().getTimezoneOffset(),
    isHidden: false,
  };
};

export const getEmptyAccordionSection = (): AccordionSection => {
  return {
    id: '',
    name: '',
    text: '',
    isHidden: false,
  };
};

export const getEmptyShopItem = (): ShopItem => {
  return {
    id: '',
    name: '',
    isHidden: false,
    price: 0,
    description: '',
    isSoldOut: false,
    hasStock: false,
    isAgeGated: false,
    minimumAge: 0,
    stock: 0,
    modifierGroups: [],
    images: [],
    minimumQuantity: 1,
    maximumQuantity: 1000,
    hasUniqueTaxRate: false,
    uniqueTaxRate: 0,
  };
};

export const getEmptyLink = (): Link => {
  return {
    id: '',
    name: '',
    url: '',
    description: '',
    images: [],
    isEmbedded: false,
    isHidden: false,
  };
};

export const getEmptyCustomField = (): CustomField => {
  return {
    id: '',
    name: '',
    type: eCustomFieldTypes.shortText,
    isRequired: true,
    choices: [],
    description: '',
    signatureStatement: '',
  };
};

export const COUNTRY_CODES = ['de', 'es', 'fr', 'zh', 'en', 'fi', 'sv'];

export const LOCALES = {
  en: 'English',
  fr: 'Français',
  de: 'Deutsche',
  es: 'Español',
  zh: '简体中文',
  fi: 'Finnish',
  sv: 'Swedish',
};

export const defaultThemes: Record<string, AestheticTheme> = {
  sitchLight: {
    id: 'sitchLight',
    name: 'Sitch Light',
    backgroundColor: '#ededf7',
    images: [],
    accentColor: '#0270d9',
    primaryFontName: 'Poppins',
    headerFontName: 'Poppins',
    primaryFontVariant: 'regular',
    headerFontVariant: 'bold',
    primaryFontScale: 1,
    headerFontScale: 1,
    uppercaseHeaderFont: false,
    cardStyle: eCardStyle.round,
    secondaryImages: [],
    ambientText: '',
    metaTheme: eMetaTheme.light,      
  },
  sitchDark: {
    id: 'sitchDark',
    name: 'Sitch Dark',
    backgroundColor: '#212121',
    images: [],
    accentColor: '#a1d1ff',
    primaryFontName: 'Poppins',
    headerFontName: 'Poppins',
    primaryFontVariant: 'regular',
    headerFontVariant: 'bold',
    primaryFontScale: 1,
    headerFontScale: 1,
    uppercaseHeaderFont: false,
    cardStyle: eCardStyle.round,
    secondaryImages: [],
    ambientText: '',
    metaTheme: eMetaTheme.dark,
  },
  flashy: {
    id: 'flashy',
    name: 'Flashy',
    backgroundColor: 'linear-gradient(to right, #d66547, #58b8c7)',
    images: [],
    accentColor: '#aaffd5',
    primaryFontName: 'Poppins',
    headerFontName: 'Poppins',
    primaryFontVariant: 'regular',
    headerFontVariant: 'bold',
    primaryFontScale: 1,
    headerFontScale: 1,
    uppercaseHeaderFont: true,
    cardStyle: eCardStyle.round,
    secondaryImages: [],
    ambientText: '',
    metaTheme: eMetaTheme.light,
  },
  happy: {
    id: 'happy',
    name: 'Happy',
    backgroundColor: 'linear-gradient(to right, #ff4b1f, #a83279)',
    images: [],
    accentColor: '#9be6ff',
    primaryFontName: 'Quicksand',
    headerFontName: 'Quicksand',
    primaryFontVariant: 'regular',
    headerFontVariant: 'bold',
    primaryFontScale: 1,
    headerFontScale: 1,
    uppercaseHeaderFont: false,
    cardStyle: eCardStyle.round,
    secondaryImages: [],
    ambientText: '',
    metaTheme: eMetaTheme.light,
  },
  sporty: {
    id: 'sporty',
    name: 'Sporty',
    backgroundColor: 'linear-gradient(to right, #424242, #212121)',
    images: [],
    accentColor: '#ffa500',
    primaryFontName: 'Roboto',
    headerFontName: 'Roboto',
    primaryFontVariant: 'italic',
    headerFontVariant: '700italic',
    primaryFontScale: 1,
    headerFontScale: 1.1,
    uppercaseHeaderFont: true,
    cardStyle: eCardStyle.round,
    secondaryImages: [],
    ambientText: '',
    metaTheme: eMetaTheme.dark,
  },
  techy: {
    id: 'techy',
    name: 'Techy',
    backgroundColor: 'linear-gradient(to right, #141e30, #243b55)',
    images: [],
    accentColor: '#04e9ff',
    primaryFontName: 'Open Sans',
    headerFontName: 'Roboto Condensed',
    primaryFontVariant: 'regular',
    headerFontVariant: 'regular',
    primaryFontScale: 1,
    headerFontScale: 1,
    uppercaseHeaderFont: true,
    cardStyle: eCardStyle.round,
    secondaryImages: [],
    ambientText: '',
    metaTheme: eMetaTheme.dark,
  },
  classy: {
    id: 'classy',
    name: 'Classy',
    backgroundColor: '#fff8e1',
    images: [],
    accentColor: '#fd8544',
    primaryFontName: 'Quicksand',
    headerFontName: 'Raleway',
    primaryFontVariant: '500',
    headerFontVariant: '600',
    primaryFontScale: 1,
    headerFontScale: 0.9,
    uppercaseHeaderFont: true,
    cardStyle: eCardStyle.round,
    secondaryImages: [],
    ambientText: '',
    metaTheme: eMetaTheme.light,
  },
  healthy: {
    id: 'healthy',
    name: 'Healthy',
    backgroundColor: '#f1f8e9',
    images: [],
    accentColor: '#2caf15',
    primaryFontName: 'Quicksand',
    headerFontName: 'Economica',
    primaryFontVariant: '500',
    headerFontVariant: 'bold',
    primaryFontScale: 1,
    headerFontScale: 1.2,
    uppercaseHeaderFont: false,
    cardStyle: eCardStyle.round,
    secondaryImages: [],
    ambientText: '',
    metaTheme: eMetaTheme.light,
  },
};

export const demoAccountUserId = 'thU6a3OaF8VvUUEmf67xhM7YPVX2';

export const endpoints = {
  // Stripe endpoints.
  getCurrencyData: 'get-currency-data',
  getStripeAccount: 'get-stripe-account',
  getStripeSubscription: 'get-stripe-subscription',
  cancelStripeSubscription: 'cancel-stripe-subscription',
  reactivateStripeSubscription: 'reactivate-stripe-subscription',
  stripeConnectWebhook: 'stripe-connect-webhook',
  stripeWebhook: 'stripe-webhook',
  connectStripeAccount: 'connect-stripe-account',
  createStripeIntent: 'create-stripe-intent',
  createRefund: 'create-refund',
  saveOrderBreakdown: 'create-order-breakdown',
  getAllSubscriptions: 'get-all-subscriptions',
  cancelSubscription: 'cancel-subscription',
  getInvoices: 'get-invoices',
  saveCustomFieldsForPayment: 'save-custom-fields-for-payment',
  getPromoCodePayments: 'get-promo-code-payments',
  // Sitch Client Form submissions.
  customFormSubmit: 'custom-form-submit',
  // Permalinks.
  createCustomPermalink: 'create-custom-permalink',
  createShortPermalink: 'create-short-permalink',
  // Sitch API.
  sitchApi: 'sitch-api',
  // File Generation
  vcard: 'vcard',
  ical: 'ical',
  // Chat
  joinChat: 'join-chat',
  sendChatMessage: 'send-chat-message',
  deleteChatMessage: 'delete-chat-message',
  updateChatMessage: 'update-chat-message',
  updateChatUser: 'update-chat-user',
  addWebPushTokenToChat: 'add-web-push-notification-to-chat',
  removeWebPushTokenFromChat: 'remove-web-push-notification-from-chat',
  leaveChat: 'leave-chat',
  deleteChat: 'delete-chat',
  resetChat: 'reset-chat',
  // Translations
  doTranslations: 'do-translations',
  // External Groups
  setActiveModeIdWithExternalGroupCode: 'set-active-mode-id-with-external-group-code',
  setAutoSitchWithExternalGroupCode: 'set-auto-sitch-with-external-group-code',
  getExternalUseModeGroup: 'get-external-use-mode-group',
  findTeamModeFromDocId: 'find-team-mode-from-doc-id',
  // Mail
  sendVerificationEmail: 'send-verification-email',
  verifyEmail: 'verify-email',
  unsubscribeFromMarketingEmails: 'unsubscribe-from-marketing-emails',
  manuallyVerifyEmail: 'manually-verify-email',
  // Booking.
  bookAppointment: 'book-appointment',
  cancelBooking: 'cancel-booking',
  getBookingSlotSpacesRemaining: 'get-booking-slot-spaces-remaining',
  setReminder: 'set-reminder',
  // Sockets.
  setUpPresence: 'set-up-presence',
  // Misc.
  affiliateSubmit: 'affiliate-submit',
  activateSitchLink: 'activate-sitch-link',
  notificationTest: 'notification-test',
  getCountryFromIp: 'get-country-from-ip',
  sendProductSiteTelemetry: 'send-product-site-telemetry',
  createUser: 'create-user',
};

export const stripe = {
  error: {
    AMOUNT_TOO_SMALL: 'amount_too_small'
  }
};