import { endpoints } from '@/constants';
import { eDeliveryTypes, eModeTaxRateType } from '@/enums';
import { store } from '@/store';
import { standardApiFetch } from './api-utils';

export const formatCurrency = (amount = 0, currency = '', showSign = false, addHtml = false, alwaysAddDecimalTwoPlaces = false): string => {
  amount = amount || 0;

  currency = currency.toUpperCase();

  const zeroDecimalCurrencies = ['BIF', 'CLP', 'DJF', 'GNF', 'JPY', 'KMF', 'KRW', 'MGA', 'PYG', 'RWF', 'UGX', 'VND', 'VUV', 'XAF', 'XOF', 'XPF'];

  let CENTS_DOLLARS_MULTIPLIER = 100;

  if (zeroDecimalCurrencies.includes(currency)) {
    CENTS_DOLLARS_MULTIPLIER = 1;
  }

  // Convery from int to float now that the accounting is done.
  amount = amount / CENTS_DOLLARS_MULTIPLIER;

  const arr: string[] = Number(Math.abs(amount)).toFixed(2).split('.');
  let ret = '';
  if (showSign) {
    ret += addHtml ? `<span class="amount-sign">${amount > 0 ? '+' : '-'}</span>` : amount > 0 ? '+' : '-';
  }

  const symbol = store.state.savedMoneySymbols[currency] || currency;
  ret += addHtml ? `<span class="money-symbol">${symbol}</span>` : symbol;
  const dollars: string = arr[0];
  const cents: string = arr[1];
  if (CENTS_DOLLARS_MULTIPLIER === 1 || (cents === '00' && !alwaysAddDecimalTwoPlaces)) {
    ret += addHtml ? `<span>${dollars}</span>` : `${dollars}`;
  } else {
    ret += addHtml ? `<span>${dollars}</span><span class="small-amount">.${cents}</span>` : `${dollars}.${cents}`;
  }
  return ret;
};

export const getTaxRateForMode = (mode: StripeEnabledMode, deliveryAddress: DetailedAddress | null) => {
  let givenLocationIsIncludedInSelectedTaxRateLocations = false;
  const locationsToComputeTaxRateFor = mode.locationsToComputeTaxRateFor;
  // Only attempt to look at location based sales tax rates if we have a delivery address with an address value and this mode is set up for location based taxation and it has locations to get the tax rate for.
  if (
    deliveryAddress &&
    deliveryAddress.address &&
    (mode as ShopMode).deliveryType === eDeliveryTypes.shipping &&
    mode.computeTaxRateBasedOnLocation &&
    locationsToComputeTaxRateFor &&
    Object.keys(locationsToComputeTaxRateFor).length
  ) {
    const address = deliveryAddress.address;
    // First construct the tax location code, this is {countryCode}_{stateCode}
    const taxLocationCode = `${address.country}_${address.administrativeAreaLevel1}`.toLowerCase();
    console.log(deliveryAddress.address, taxLocationCode);
    // If we have an entry for the location code. then proceed.
    givenLocationIsIncludedInSelectedTaxRateLocations = locationsToComputeTaxRateFor[taxLocationCode] !== undefined;
    if (givenLocationIsIncludedInSelectedTaxRateLocations) {
      // And use the tax rate for that location.
      return { taxRate: locationsToComputeTaxRateFor[taxLocationCode], type: eModeTaxRateType.locationBased };
    }
  }
  // If no location based tax rate, use the default mode tax rate.
  return { taxRate: mode.taxRate || 0, type: eModeTaxRateType.default };
};

export const getOrderItemTaxValues = (item: OrderItem, mode: StripeEnabledMode, deliveryAddress: DetailedAddress | null) => {
  const modeTaxRateObject = getTaxRateForMode(mode, deliveryAddress);
  const locationBasedTaxRateSelected = modeTaxRateObject.type === 'locationBased';
  const defaultTaxRateAsALessThanOneFloat = mode.taxRate / 100;
  const uniqueTaxRateAsALessThanOneFloat = (item.uniqueTaxRate || 0) / 100;

  // Insert location tax rate here.
  const taxRateToUse = locationBasedTaxRateSelected
    ? modeTaxRateObject.taxRate / 100 // As a less than 1 float.
    : item.hasUniqueTaxRate
    ? uniqueTaxRateAsALessThanOneFloat
    : defaultTaxRateAsALessThanOneFloat;
  const totalTaxAmount = Math.round(item.totalAmount * taxRateToUse);
  const totalAmountWithTax = item.totalAmount + totalTaxAmount;
  return {
    totalTaxAmount, // The total amount of tax to pay on this item.
    totalAmountWithTax, // Total base cost of of the item (modifiers, quanity etc) + the total tax amount.
  };
};

export const getMoneySymbolIfNeeded = (currency: string): Promise<string> => {
  return new Promise((resolve) => {
    if (store.state.savedMoneySymbols[currency]) {
      resolve(store.state.savedMoneySymbols[currency]);
      return;
    } else {
      standardApiFetch(endpoints.getCurrencyData, {
        currencyCode: currency,
      })
        .then((response) => {
          const symbol: string = response.successfulResponse.currencyData.symbol;
          store.commit('savedMoneySymbols', [currency, symbol]);
          resolve(symbol);
        })
        .catch(() => {
          return resolve('');
        });
    }
  });
};
